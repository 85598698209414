import React from "react";

// reactstrap components
import { Button, Container,  Row, Col} from "reactstrap";

// image
import wine from "../../images/wines/LevandaMT.png";

function LevandaMediumDry() {

  return (
    <>
                <div className="team-player">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={wine}
                    ></img>
                    <h5 className="title">Levanda Medium Dry</h5>
                    {/* <p className="category text-info">Visit Us</p> */}
                    <p className="description">
                    A rose wine made from Grenache, Shiraz and Mattaro. High quality color extraction occurs during 
                    the 8-10 hours cryomaceration of the grapes inside the stainless-steel tanks. A fresh wine which 
                    is very easy to drink with notes of fresh fruity aromas.  
                    </p>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Optimum consume temp:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    10 – 12<span>&#8451;</span>
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Alcohol level:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    13-13,5%
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Color:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    Light Red, Rose
                    </p>
                    </Col>
                    </Row>
                  </div>
    </>
  );
}

export default LevandaMediumDry;
