import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './index.css';

// styles
import "../src/assets/css/bootstrap.min.css";
import "../src/assets/css/now-ui-kit.css";
// import "assets/css/now-ui-kit.min.css";
// import "assets/css/now-ui-kit.css.map";
import "../src/assets/demo/demo.css";

import App from './App';
import NavbarComponent from "../src/assets/components/NavbarComponent.js";
import FooterComponent from "../src/assets/components/FooterComponent.js";

import LandingPage from "./assets/views/LandingPage"
import OurStoryPage from './assets/views/OurStoryPage.js'
import WinesPage from './assets/views/WinesPage.js'
import VisitUsPage from './assets/views/VisitUsPage.js'
import BlogsPage from './assets/views/BlogsPage.js'
import BlogPage from './assets/views/BlogPage.js'
import reportWebVitals from './reportWebVitals';
import Analytics from './Analytics.js'


function Routes(){
  Analytics()
  return(
      <Switch>
        <Route
          path="/home"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/ourStory"
          render={(props) => <OurStoryPage {...props} />}
        />
        <Route
          path="/wines"
          render={(props) => <WinesPage {...props} />}
        />
        <Route
          path="/visitUs"
          render={(props) => <VisitUsPage {...props} />}
        />
        <Route
          path="/blogs"
          render={(props) => <BlogsPage {...props} />}
        />
        <Route
          path="/blog"
          render={(props) => <BlogPage {...props} />}
        />
        <Redirect to="/home" />
        <Redirect from="/" to="/home" />
      </Switch>
  )
}

ReactDOM.render(

  <BrowserRouter>
    <NavbarComponent/>
    <Routes/>
    <FooterComponent/>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
