import React from "react";
// reactstrap components
import { Link } from "react-router-dom";
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import HeaderComponent from "../components/HeaderComponent.js";

import wines from "../images/wines-resized.jpg";
import ourStory from "../images/winery-resize.jpg";
import visitUs from "../images/visitUs-resize.jpg";

function LandingPage() {
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  return (
    <>
      <div className="wrapper">
        <HeaderComponent />
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Explore</h2>
            <div className="team">
              <Row>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={ourStory}
                    ></img>
                    <h4 className="title">Our Story</h4>
                    {/* <p className="category text-info">Model</p> */}
                    <p className="description">
                    Established in 1992, Constantinou Winery can offer you premium wines made from indigenous 
                    and international cultivars . Our goal is to prove that the Cypriot wine can compete and can 
                    be competed from each wine made from the most well-known winemaking regions.
                    </p>
                    <Link to="/ourStory" tag={Link}>
                    <Button className="findmore">Find More</Button>
                    </Link>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={wines}
                    ></img>
                    <h4 className="title">Wines</h4>
                    {/* <p className="category text-info">Designer</p> */}
                    <p className="description">
                    Eight different type of labels made with extreme caution firstly to the raw material and 
                    secondly to the winemaking techniques used for every product. Single variety labels and blended wines are made from 
                    indigenous and international cultivars.
                    </p>
                    <Link to="/wines" tag={Link}>
                    <Button className="findmore">Find More</Button>
                    </Link>
                  </div>
                </Col>
                <Col md="4">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={visitUs}
                    ></img>
                    <h4 className="title">Visit Us</h4>
                    {/* <p className="category text-info">Visit Us</p> */}
                    <p className="description">
                    Located twenty five minutes from Limassol town in the village of Pera Pedi. 
                    You can enjoy your visit by tasting our premium wines, learning about the process of winemaking 
                    and enjoying the amazing mountain view.
                    </p>
                    <Link to="/visitUs" tag={Link}>
                    <Button className="findmore">Find More</Button>
                    </Link>
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPage;
