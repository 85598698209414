import React, {Suspense} from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import {useLocation} from "react-router-dom";

import Article21_04_2021 from '../components/blogs/article/Article21_04_2021.js'

function BlogPage() {
  React.useEffect(() => {
    document.body.classList.add("blog-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);

  let data = useLocation();

  const Component = React.lazy(() =>
    import(`../components/blogs/article/${data.state.article}.js`)
    );
  return (
    <>
      <div className="wrapper">
      <Suspense fallback={<div>Loading...</div>}>
          <Component />
      </Suspense>
      </div>
    </>
  );
}

export default BlogPage;
