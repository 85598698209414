import React from "react";

// reactstrap components
import { Button, Container,  Row, Col} from "reactstrap";

// image
import wine from "../../images/wines/CabeSauvT.png";

function CabernetSauvignon() {

  return (
    <>
                <div className="team-player">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={wine}
                    ></img>
                    <h5 className="title">Cabernet Sauvignon</h5>
                    {/* <p className="category text-info">Visit Us</p> */}
                    <p className="description">
                    A red dry wine made with extreme caution to the raw material (grapes). Constantinou Cabernet 
                    Sauvignon is made 75% of Cabernet Sauvignon grapes and 25% of Cabernet Franc grapes. A full-bodied 
                    wine with herbaceous characteristic notes from the two grapes varieties. The aging stage occurs for 
                    12 months in French oak barrels and another 12 months in bottle.    
                    </p>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Optimum consume temp:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    18-20<span>&#8451;</span>
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Alcohol level:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    14-14,5%
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Color:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    Deep Red
                    </p>
                    </Col>
                    </Row>
                  </div>
    </>
  );
}

export default CabernetSauvignon;
