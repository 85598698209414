import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

import logo from '../images/Constantinou_Winery_Logo_Only.png'
import logoText from '../images/Constantinou_Winery_Text.png'

function NavbarComponent() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
        //navbar-transparent
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top " + navbarColor} expand="lg" color="main">
        <Container style={{display: 'flex'}}>
          <div className="navbar-translate">
            <div className="navbar-brand">
              <Link onClick={() => {
                  setCollapseOpen(false);
                }} to="/home">
                <img
                    alt="..."
                    src={logo}
                    className="logo-container"
                ></img>
              </Link>
            </div>
            <div>
              <button
                className="navbar-toggler navbar-toggler"
                onClick={() => {
                  document.documentElement.classList.toggle("nav-open");
                  setCollapseOpen(!collapseOpen);
                }}
                aria-expanded={collapseOpen}
                type="button"
              >
                <span className="navbar-toggler-bar top-bar"></span>
                <span className="navbar-toggler-bar middle-bar"></span>
                <span className="navbar-toggler-bar bottom-bar"></span>
              </button>
            </div>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink onClick={() => {
                setCollapseOpen(false);
              }} to="/ourStory" tag={Link}>
                  Our Story
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {
                setCollapseOpen(false);
              }} to="/wines" tag={Link}>
                  Wines
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink onClick={() => {
                setCollapseOpen(false);
              }} to="/visitUs" tag={Link}>
                  Visit Us
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/blogs" tag={Link}>
                  Blog
                </NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default NavbarComponent;