import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import HeaderWinesComponent from "../components/HeaderWinesComponent.js";
import AyioklimaDry from "../components/wines/AyioklimaDry.js"
import AyioklimaMediumDry from "../components/wines/AyioklimaMediumDry.js"
import LevandaDry from "../components/wines/LevandaDry.js"
import LevandaMediumDry from "../components/wines/LevandaMediumDry.js"
import Veloudon from "../components/wines/Veloudon.js"
import ConstantinouMediumDry from "../components/wines/ConstantinouMediumDry.js"
import Shiraz from "../components/wines/Shiraz.js"
import CabernetSauvignon from "../components/wines/CabernetSauvignon.js"


function WinesPage() {
  React.useEffect(() => {
    document.body.classList.add("wines-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("wines-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <HeaderWinesComponent />
        <div className="section section-team text-center">
          <Container>
            <h2 className="title">Wines</h2>
            <h4 className="title" style={{color: "burlywood"}}>White Wines</h4>
            <div className="team text-center">
              <Row>
                <Col md="6">
                  <AyioklimaDry/>
                </Col>
                <Col md="6">
                  <AyioklimaMediumDry/>
                </Col>
              </Row>
            </div>
            <h4 className="title" style={{color: "burlywood"}}>Rose Wines</h4>
            <div className="team text-center">
              <Row>
                <Col md="6">
                  <LevandaDry/>
                </Col>
                <Col md="6">
                  <LevandaMediumDry/>
                </Col>
              </Row>
            </div>
            <h4 className="title" style={{color: "burlywood"}}>Red Wines</h4>
            <div className="team text-center">
              <Row>
                <Col md="6">
                  <Veloudon/>
                </Col>
                <Col md="6">
                  <ConstantinouMediumDry/>
                </Col>
                <Col md="6">
                  <Shiraz/>
                </Col>
                <Col md="6">
                  <CabernetSauvignon/>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default WinesPage;
