import React from "react";

// reactstrap components
import { Button,Card,CardBody,CardImg,CardTitle,CardSubtitle,CardText} from "reactstrap";
import { Link } from "react-router-dom";

// image
import img1 from "../../images/blogs/A23_11_2024/xorigia.jpg";

function C23_11_2024() {

  return (
    <>
    <div>
    <Card>
        <CardImg top width="100%" style={{height: '200px'}} src={img1} alt="Card image cap" className="img-blogs" />
        <CardBody>
          <CardTitle tag="h5">ΣΤΡΑΤΗΓΙΚΟΥ ΣΧΕΔΙΟΥ ΚΟΙΝΗΣ ΑΓΡΟΤΙΚΗΣ ΠΟΛΙΤΙΚΗΣ</CardTitle>
          <CardSubtitle className="mb-2 text-muted">Wednesday 23/11/2024</CardSubtitle>
          <CardText>Στα πλαίσια του ΣΤΡΑΤΗΓΙΚΟΥ ΣΧΕΔΙΟΥ ΚΟΙΝΗΣ ΑΓΡΟΤΙΚΗΣ ΠΟΛΙΤΙΚΗΣ 2023-2027 ΠΑΡΕΜΒΑΣΗ ΕΠΣΑ 2...</CardText>
          <Link to=
          {{
            pathname: "/blog",
            state: { article: 'Article23_11_2024'}
            }} tag={Link}>
          <Button className="findmore">Find More</Button>
          </Link>
        </CardBody>
      </Card>
    </div>
    </>
  );
}

export default C23_11_2024;
