import React from "react";

// reactstrap components
import { Button, Container,  Row, Col} from "reactstrap";

// image
import wine from "../../images/wines/AyioklimaDT.png";

function AyioklimaDry() {

  return (
    <>
                <div className="team-player">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={wine}
                    ></img>
                    <h5 className="title">Ayioklima Dry</h5>
                    {/* <p className="category text-info">Visit Us</p> */}
                    <p className="description">
                    A white wine made from 100% Xinisteri an indigenous grape cultivar of Cyprus. 
                    The old and well cultivated vineyards supply the winery with the best possible raw material 
                    for the production of such wine. A well balanced fresh white wine which has the potential to 
                    be aged in bottle for approximately 2 years, the combination of some citrus, tropical and ripe 
                    aromas, the medium acidities and the high intensity in mouthfeel enable the chance to the 
                    consumer to enjoy a high-quality wine. 
                    </p>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Optimum consume temp:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    9-11<span>&#8451;</span>
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Alcohol level:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    13,5 – 14%
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Color:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    Green-Yellowish
                    </p>
                    </Col>
                    </Row>
                  </div>
    </>
  );
}

export default AyioklimaDry;
