import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";

import backgroundImage from "../images/blog.jpg";

// core components

function HeaderBlogsComponent() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991 && pageHeader?.current) {
      // const updateScroll = () => {
      //   let windowScrollTop = window.pageYOffset / 3;
      //   pageHeader.current.style.transform =
      //     "translate3d(0," + windowScrollTop + "px,0)";
      // };
      // window.addEventListener("scroll", updateScroll);
      // return function cleanup() {
      //   window.removeEventListener("scroll", updateScroll);
      // };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image-small"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
          ref={pageHeader}
        ></div>
      </div>
    </>
  );
}

export default HeaderBlogsComponent;
