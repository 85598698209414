import logo from './logo.svg';
import './App.css';
import NavbarComponent from '../src/assets/components/NavbarComponent'
import HeaderComponent from './assets/components/HeaderComponent'
import FooterComponent from '../src/assets/components/FooterComponent'
import LandingPage from './assets/views/LandingPage';

function App() {
  return (
    <>
    <LandingPage/>
    </>
  );
}

export default App;
