import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import HeaderStoryComponent from "../components/HeaderStoryComponent.js";

import grapes from "../images/DSC_6826.jpg";
import barrels from "../images/DSC_6748.jpg";
import wines from "../images/wines.jpg";
import costas from "../images/costas-constantinou.jpg"

function OurStoryPage() {
  React.useEffect(() => {
    document.body.classList.add("ourStory-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("ourStory-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <HeaderStoryComponent />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">How it started?</h2>
                <h5 className="description">
                Constantinou Winery was founded in 1992 by Costas Constantinou, an ambitious young businessman born 
                in Pera Pedi village, located on the outskirts of Limassol District in Cyprus. The winery initially 
                started out with the production of liqueurs, with the first product being the Orange Liqueur “Orali” 
                that is made from ripe oranges and has the ability to give fresh aromas and tastes. Constantinou later 
                on produced the Coffee Liqueur which was a huge success amongst tourst and locals and is still being 
                sold today. The business was later known as “Orali” after the first liqueur produced and has remains 
                that way ever since. 

                </h5>
              </Col>
            </Row>
            <div className="separator separator-primary"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="7">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                      `url(${grapes})`,
                    }}
                  >
                    <p className="blockquote blockquote-info">
                      "For us Winemaking is a process without tomorrow. When we are 
		                  satisfied with ourselves, that we have done the best we could, at least 
		                  the product will express our efforts" <br></br>
                      <br></br>
                      <small>-Costas Constantinou</small>
                    </p>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      backgroundImage:
                      `url(${wines})`,
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                      `url(${barrels})`,
                    }}
                  ></div>
                  <h3>
                    What came next?
                  </h3>
                  <p>
                  A few years later, Constantinou wanted to expand his liqueur business further and he decided to 
                  explore the world of wines. In 2000 he produced his first white dry wine named “Ayioklima”.  
                  The first harvest took place in a small stone built house in Pera Pedi Village and remained there 
                  until 2009. During the first years Constantinou was entering a whole new world of wines, in which he 
                  spent his time, experimenting, researching and studying the art of wines. Through this he was able to 
                  focus on the true philosophy and art behind the wine production process. Due to increase production 
                  and success of the business, in August 2009 the winery was relocated to a brand new building of 
                  800sm dividend. It now consists of the entrance meet and greet room, wine tasting, receiving grapes, 
                  labelling area, a cellar, offices and wine production. There is now new and high technology machinery 
                  to produce wines to the highest standard of quality.
                  </p>
                  <p>
                  Constantinou Winery owns 10 acres of vineyards and co-operate with more than 30 vine growers in the 
                  area. The annual production of the winery rises up to 200.000 bottles with 8 different wine labels. 
                  We aim to raise our vineyards up to 50 acres in 5 years which will enables us to focus on the production 
                  of new quality wines.  
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        <div className="separator-line"></div>
        <div className="section section-team text-center">
        <Container>
            <h2 className="title">The Team</h2>
            <div className="team text-center">
              <Row>
                <Col md="6">
                  <h5 className="title">COSTAS CONSTANTINOU</h5>
                  <p className="subtitle">General Director – Owner</p>
                  <p>A self-taught winemaker with more than 20 years of experience. His passion for agriculture 
                    started years ago when he was teenager. His parents owned vineyards which was his daily occupation. 
                    He believes that for the wine to be successful, it needs to be produced carefully in the vineyard 
                    and that the most important and strong weapon for a successful winemaker is good quality of grapes.   </p>
                </Col>
                <Col md="6">
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={costas}
                  ></img>
                </Col>
              </Row>
            </div>
            <div className="separator-team"></div>
            <div className="team text-center">
              <Row>
                {/* <Col md="6">
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={costas}
                  ></img>
                </Col> */}
                <Col md="12">
                  <h5 className="title">PANAGIOTIS CONSTANTINOU</h5>
                  <p className="subtitle">Oenologist - Viticulturist </p>
                  <p>After his studies at the University of Uclan Cyprus and University of Brighton he holds a 
                    bachelor’s degree in Viticulture and Oenology. He joined the family business in 2019 and brings 
                    fresh ideas to the table and is focused on bringing innovative technologies for the future of the 
                    winery. His aim is to prove that the Cyprus wine can stand on the global market competing wines 
                    from well-known, grape-growing and winemaking countries.
                  </p>
                </Col>
              </Row>
            </div>
            <div className="separator-team"></div>
            <div className="team text-center">
              <Row>
                <Col md="12">
                  <h5 className="title">CHRISTAKIS EVAGOROU</h5>
                  <p className="subtitle">Agriculturist</p>
                  <p>Christakis became a part of the winery’s team beginning of 2021. He is a well- trained and 
                    experienced viticulturist since he has been in the fields from a very young age helping his 
                    father. He is a passionate person who ties extravagantly with the other team. His studies in 
                    agriculture gave him the knowledge to monitor and grow the vines in a modern and more sustainable 
                    way. He focuses in the production of high-quality grapes and supply the winery with top quality 
                    raw material.
                  </p>
                </Col>
                {/* <Col md="6">
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={costas}
                  ></img>
                </Col> */}
              </Row>
            </div>
            <div className="separator-team"></div>
            <div className="team text-center">
              <Row>
                <Col md="12">
                  <h5 className="title">GEORGE IOAKIM</h5>
                  <p className="subtitle">Salesman</p>
                  <p>George is a member of winery's team for almost a decade since 2012. He has excellent communication skills and 
                    his responsibilities are the sales of the winery. Delivering and Meeting customers all over the island consider 
                    as important as the winemaking part.
                  </p>
                </Col>
                {/* <Col md="6">
                  <img
                      alt="..."
                      className="img-fluid img-raised"
                      src={costas}
                  ></img>
                </Col> */}
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default OurStoryPage;
