import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import CarouselComponent from './CarouselComponent.js'

import backgroundImage from "../images/DSC_6804.jpg";
import logoText from '../images/Constantinou_Winery_Text.png'

// core components

function HeaderStoryComponent() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header page-header-small">
        <div
          className="page-header-image-small"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
          ref={pageHeader}
        ></div>
        {/* <div className="content-logo-text">
          <Container>
            <div className="text-center">
              <img
                 alt="..."
                 src={logoText}
                 className="logo-container-text"
                ></img>
            </div>
          </Container>
        </div> */}
      </div>
    </>
  );
}

export default HeaderStoryComponent;
