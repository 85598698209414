import React from "react";

// reactstrap components
import { Button, Container,  Row, Col} from "reactstrap";

// image
import wine from "../../images/wines/ShirazT.png";

function Shiraz() {

  return (
    <>
                <div className="team-player">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={wine}
                    ></img>
                    <h5 className="title">Shiraz</h5>
                    {/* <p className="category text-info">Visit Us</p> */}
                    <p className="description">
                    A red dry wine made with extreme caution to the raw material (grapes). Shiraz Constantinou is 
                    made 100% from shiraz grapes from well-established and well grown vineyards. A full-bodied wine 
                    which pass 12 months in oak barrels and another 12 months in bottles before its release in the 
                    market. Malolactic Fermentation improve the organoleptic part of the wine with tastes of butter. 
                    This process has the ability to bring the wine into a microbial stability and decrease its acidic 
                    aspect.   
                    </p>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Optimum consume temp:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    18-20<span>&#8451;</span>
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Alcohol level:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    14-14,5%
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Color:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    Deep Red
                    </p>
                    </Col>
                    </Row>
                  </div>
    </>
  );
}

export default Shiraz;
