import React from "react";

// reactstrap components
import { Button, Container,  Row, Col} from "reactstrap";

// image
import wine from "../../images/wines/VeloudonT.png";

function Veloudon() {

  return (
    <>
                <div className="team-player">
                    <img
                      alt="..."
                      className="img-fluid"
                      src={wine}
                    ></img>
                    <h5 className="title">Veloudon</h5>
                    {/* <p className="category text-info">Visit Us</p> */}
                    <p className="description">
                    A blended red wine made from four different cultivars (Shiraz, Grenache, Mattaro and Cabernet 
                    Sauvignon). A well-balanced fresh wine with some organoleptic notes of an aged wine. The process 
                    of Malolactic Fermentation which occurs after the Primary Fermentation has the ability to decrease 
                    the acidity of the wine and support its organoleptic character with some buttery flavors and aromas. 
                    Full bodied wine with 3-5 years of aging potential.  
                    </p>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Optimum consume temp:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    18-20<span>&#8451;</span>
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Alcohol level:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    13,5-14%
                    </p>
                    </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                    <p className="description-extra1">
                    Color:
                    </p>
                    </Col>
                    <Col md="6">
                    <p className="description-extra2">
                    Deep Red
                    </p>
                    </Col>
                    </Row>
                  </div>
    </>
  );
}

export default Veloudon;
