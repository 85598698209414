import React from "react";

// reactstrap components
import { Button, Container,  Row, Col,Card,CardBody,CardImg,CardTitle,CardSubtitle,CardText} from "reactstrap";
import { Link } from "react-router-dom";


import Article21_04_2021 from '../blogs/article/Article21_04_2021.js'
// image
import img1 from "../../images/blogs/A21_04_2021/vine1.jpg";

function C21_04_2021() {

  return (
    <>
    <div>
    <Card>
        <CardImg top width="100%" style={{height: '200px'}} src={img1} alt="Card image cap" className="img-blogs" />
        <CardBody>
          <CardTitle tag="h5" style={{minHeight: '10%'}}>Bleeding – Budburst of the vineyard</CardTitle>
          <CardSubtitle className="mb-2 text-muted" style={{minHeight: '10%'}}>Wednesday 27/04/2021</CardSubtitle>
          <CardText style={{minHeight: '40%'}}>Bleeding consider to be a natural pathway of a vine. It is the first sign of transition from dormancy to active growth which... </CardText>
          <Link to=
          {{
            pathname: "/blog",
            state: { article: 'Article21_04_2021'}
            }} tag={Link}>
          <Button className="findmore">Find More</Button>
          </Link>
        </CardBody>
      </Card>
    </div>
    </>
  );
}

export default C21_04_2021;
