import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import HeaderVisitComponent from "../components/HeaderVisitComponent.js";

//images
import tasting from "../images/wineTasting-resize.jpg"
import tour from "../images/DSC_6761.jpg"

function VisitUsPage() {
  React.useEffect(() => {
    document.body.classList.add("visitUs-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("visitUs-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, []);
  return (
    <>
      <div className="wrapper">
        <HeaderVisitComponent />
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Visit Us</h2>
                <h5 className="description">
                The winery is open for tours and to give you the opportunity to become familiar with the different 
                stages of the wine production and improve your knowledge in winemaking. Our aim through the Winery Tour and Wine Tasting experience 
                is for us to explain each step of the wine production and provide you a wine tasting experience with 
                a professional approach to the different organoleptic taste techniques. 
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-team text-center">
          <Container>
            <div className="team text-center">
              <Row>
                <Col md="6">
                  <img
                      alt="..."
                      className="img-fluid img-raised img-visit"
                      src={tour}
                    ></img>
                    <h5 className="title">Winery Tour</h5>
                  <p>Constantinou Winery Tour 
                  starts from the receipt of the grapes from the vine during  harvest period (August, September, October) 
                  and ends at the cellar where the final stages of winemaking prior to bottling take place.
                  </p>
                </Col>
                <Col md="6">
                  <img
                      alt="..."
                      className="img-fluid img-raised img-visit"
                      src={tasting}
                    ></img>
                    <h5 className="title">Wine Tasting</h5>
                  <p>
                  As far as 
                  the Wine Tasting, you will have the opportunity to taste the wines of your choice and meet each 
                  organoleptic characteristic of each wine.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
}

export default VisitUsPage;
